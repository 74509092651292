import React, { useEffect, useState } from "react";
import BlogSection from "../../components/Shared/BlogSection/BlogSection";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { getPosts } from "../../actions/posts";
import SEO from "../../hooks/Seo";

const Archive = () => {
  <SEO
    title="Our Blog | Elevated Coffee Experince"
    description="eleva coffee blog"
    name="our-blog"
    type="our-blog"
  />;
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
  }, [currentId, dispatch]);

  return (
    <>
      <Box marginTop={"10%"}>
        <BlogSection setCurrentId={setCurrentId} />
      </Box>
    </>
  );
};

export default Archive;
