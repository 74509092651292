import { Box } from "@mui/material";
import React from "react";
import craft_t from "../../../../assets/Home/5th/crafting_tommorow.png";
import blends from "../../../../assets/Home/5th/eleva_coffee_blends_text.png";
import dual_curve from "../../../../assets/Home/5th/curve-3.png";
import taste from "../../../../assets/Home/5th/taste_the_legacy.png";
import { useStyle } from "./style";
const Slide1St = () => {
  const classes = useStyle();
  return (
    <>
      <Box>
        <Box className={classes.Craft_Box}>
          <Box component={"img"} src={craft_t} className={classes.Craft} />
        </Box>
        <Box className={classes.blends_Box}>
          <Box component={"img"} src={blends} className={classes.blends} />
        </Box>
        <Box className={classes.taste_Box}>
          <Box>
            <Box component={"img"} src={taste} className={classes.taste} />
          </Box>
          <Box className={classes.curve_Box}>
            <Box component={"img"} src={dual_curve} className={classes.curve} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Slide1St;
