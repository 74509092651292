import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  story_Box: {
    width: "100%",
    "@media (min-width:0px)": { marginTop: "-10%", marginBottom: "5%" },
    "@media (min-width:600px)": { marginTop: "-5%", marginBottom: "5%" },
    "@media (min-width:900px)": { marginTop: "-5%", marginBottom: "5%" },
    "@media (min-width:1200px)": { marginTop: "-5%", marginBottom: "5%" },
    "@media (min-width:1536px)": { marginTop: "-0%", marginBottom: "5%" },
  },

  cup_Box: {
    width: "100%",
    "@media (min-width:0px)": { marginBottom: "-20%", marginLeft: "-30%" },
    "@media (min-width:600px)": { marginBottom: "-20%", marginLeft: "-25%" },
    "@media (min-width:900px)": { marginBottom: "-15%", marginLeft: "-22%" },
    "@media (min-width:1200px)": {
      marginBottom: "-15%",
      marginLeft: "-25%",
    },
    "@media (min-width:1536px)": { marginBottom: "-15%", marginLeft: "-25%" },
  },
  cup_Image: {
    width: "100%",
    "@media (min-width:0px)": { width: "25%" },
    "@media (min-width:600px)": { width: "25%" },
    "@media (min-width:900px)": { width: "20%" },
    "@media (min-width:1200px)": {
      width: "15%",
    },
    "@media (min-width:1536px)": { width: "15%" },
  },

  every_cup: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%", transform: "rotate(0deg)" },
    "@media (min-width:600px)": { width: "85%", transform: "rotate(0deg)" },
    "@media (min-width:900px)": { width: "75%", transform: "rotate(0deg)" },
    "@media (min-width:1200px)": { width: "75%", transform: "rotate(0deg)" },
    "@media (min-width:1536px)": { width: "75%", transform: "rotate(0deg)" },
  },

  bean_Box: {
    width: "100%",
    "@media (min-width:0px)": {
      marginTop: "-15%",
      paddingLeft: "40%",
      transform: "rotate(0deg)",
    },
    "@media (min-width:600px)": {
      marginTop: "-12%",
      paddingLeft: "40%",
      transform: "rotate(0deg)",
    },
    "@media (min-width:900px)": {
      marginTop: "-12%",
      paddingLeft: "40%",
      transform: "rotate(0deg)",
    },
    "@media (min-width:1200px)": {
      marginTop: "-12%",
      paddingLeft: "40%",
      transform: "rotate(0deg)",
    },
    "@media (min-width:1536px)": {
      marginTop: "-12%",
      paddingLeft: "40%",
      transform: "rotate(0deg)",
    },
  },
  bean_Image: {
    width: "100%",
    "@media (min-width:0px)": { width: "28%" },
    "@media (min-width:600px)": { width: "18%" },
    "@media (min-width:900px)": { width: "18%" },
    "@media (min-width:1200px)": {
      width: "18%",
    },
    "@media (min-width:1536px)": { width: "18%" },
  },
});
