import { Box } from "@mui/material";
import React from "react";
import Brewed from "../../../../assets/Home/4th/Brewed_with_heritage.png";
import grand from "../../../../assets/Home/4th/enrique_baltodano_image.png";
import enreq from "../../../../assets/Home/4th/enrique_baltodano_text_illistration.png";
import took from "../../../../assets/Home/4th/text_with_curve.png";
import curve1 from "../../../../assets/Home/4th/curve-1.png";
import about from "../../../../assets/Home/4th/emilio_baltodano_text_illistration.png";
import emilio from "../../../../assets/Home/4th/emilio_baltodano_image.png";

import { useStyles } from "./style";

const Section4Th = () => {
  const classes = useStyles();
  return (
    <>
      {" "}
      <Box sx={{ marginY: "5%" }}>
        <Box
          component={"img"}
          src={Brewed}
          className={classes.Brewed_With_Heritage}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        sx={{ marginY: "7%" }}
      >
        <Box>
          <Box component={"img"} src={grand} className={classes.grandImage} />{" "}
        </Box>
        <Box>
          <Box component={"img"} src={enreq} className={classes.enreq_text} />{" "}
        </Box>
      </Box>
      <Box className={classes.took_box}>
        <Box component={"img"} src={took} className={classes.took_text} />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box className={classes.curve_box}>
          <Box component={"img"} src={curve1} />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} sx={{ marginY: "7%" }}>
        <Box>
          <Box component={"img"} src={about} className={classes.about_text} />
        </Box>
        <Box>
          <Box component={"img"} src={emilio} className={classes.emilioImage} />
        </Box>
      </Box>
    </>
  );
};

export default Section4Th;
