import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const PostCard = ({ post, setCurrentId }) => {
  //const ShortContent =
  //description.length > 145 ? description.substr(0, 145) + "..." : description;
  //const PostTitle = title.length > 50 ? title.substr(0, 50) + "..." : title;

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "flex-start",
          padding: "20px",
        }}
      >
        <Box>
          <Card
            sx={{
              maxWidth: 400,
              width: { xs: "90vw" },
              height: "100%",
              border: "solid 1px gold",
              m: "10px",
            }}
          >
            <NavLink
              to={`/eleva-latest-news/${post._id}`}
              style={{ textDecoration: "none" }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  width="500px"
                  height={"250px"}
                  sx={{ objectFit: "cover" }}
                  image={
                    post.selectedFile ||
                    "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
                  }
                  alt={""}
                />{" "}
              </CardActionArea>
              <CardContent sx={{ textAlign: "left" }}>
                <Typography
                  gutterBottom
                  variant="h3"
                  component="div"
                  color={"goldenrod"}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      color: "#fff",
                    },
                  }}
                >
                  {post.title?.length > 50
                    ? post.title?.substr(0, 50) + "..."
                    : post.title}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color={"text.secondary"}
                >
                  {post.createdAt} / {post.author} / {post.category}
                </Typography>
                <Typography variant="h5" color="#fff">
                  {post.content?.length > 145
                    ? post.content?.substr(0, 145) + "..."
                    : post.content}
                </Typography>
              </CardContent>

              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "15px",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    width: "50%",
                    height: "40px",
                    textTransform: "capitalize",
                    backgroundColor: "goldenrod",
                  }}
                >
                  <Box padding={1}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        textTransform: "capitalize",
                        color: "#000",
                        fontWeight: 600,
                        "&:hover": {
                          color: "#fff",
                        },
                      }}
                    >
                      Read More
                    </Typography>
                  </Box>
                </Button>
              </CardActions>
            </NavLink>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default PostCard;
