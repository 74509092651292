import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  // Define your styles here

  /*** Box_Third_Section ***/
  ///////////////////////////

  third_section_Box: {
    "@media (min-width:0px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "@media (min-width:600px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "@media (min-width:900px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  /*** Crafting_Img_Third_Section ***/
  ///////////////////////////////////
  third_section_crafting_img: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
    },
    "@media (min-width:600px)": { width: "100%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": {
      width: "60%",
      height: "20%",
      marginLeft: "-10%",
    },
    "@media (min-width:1536px)": {
      width: "60%",
      height: "20%",
      marginLeft: "-10%",
    },
  },
  /*** Crafting_left_Third_Section ***/
  ///////////////////////////////////
  third_section_crafting_left: {
    width: "100%",
    display: "flex",
    "@media (min-width:0px)": { display: "none" },
    "@media (min-width:600px)": { display: "none" },
    "@media (min-width:900px)": { display: "none" },
    "@media (min-width:1200px)": {
      width: "18%",
      height: "20%",
      marginLeft: "-25%",
      marginTop: "0.5%",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      width: "18%",
      height: "20%",
      marginLeft: "-25%",
      marginTop: "0.5%",
      display: "flex",
    },
  },
  /*** Crafting_hand_Third_Section ***/
  ///////////////////////////////////
  third_section_crafting_hand: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "100%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": { width: "60%", marginLeft: "-18%" },
    "@media (min-width:1536px)": { width: "60%", marginLeft: "-18%" },
  },
});
