import { Box } from "@mui/material";
import React from "react";
import bean from "../../../../assets/Home/6th/Slider/Slide_1/bean.png";
import everyCup from "../../../../assets/Home/6th/Slider/Slide_1/craft_coffee_journey.png";
import Source from "../../../../assets/Home/6th/Slider/Slide_1/eleva_coffee_sourcing.png";
import nicaragua_map from "../../../../assets/Home/6th/Slider/Slide_1/nicaragua_word_map.png";
import nicaragua_text from "../../../../assets/Home/6th/Slider/Slide_1/slide_1_text.png";
import { useStyle } from "./style";

const Slide2Nd = () => {
  const classes = useStyle();
  return (
    <>
      <Box display={"flex"} justifyContent={"flex-start"} px={0}>
        <Box>
          <Box component={"img"} src={Source} className={classes.Source} />
        </Box>
        <Box>
          <Box component={"img"} src={everyCup} className={classes.everyCup} />
        </Box>
        <Box className={classes.bean_box}>
          <Box component={"img"} src={bean} className={classes.bean} />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-start"} px={6}>
        <Box className={classes.nicaragua_map_box}>
          <Box
            component={"img"}
            src={nicaragua_map}
            className={classes.nicaragua_map}
          />
        </Box>
        <Box className={classes.nicaragua_text_box}>
          <Box
            component={"img"}
            src={nicaragua_text}
            className={classes.nicaragua_text}
          />
        </Box>
      </Box>
    </>
  );
};

export default Slide2Nd;
