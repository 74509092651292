import React from "react";
import bean from "../../../../assets/Home/7th/bean.png";
import cup from "../../../../assets/Home/7th/empty_coffee_cup_illistration.png";
import every_cup from "../../../../assets/Home/7th/every_cup_tells_a_story.png";
import { Box } from "@mui/material";
import { useStyles } from "./style";

const Section6Th = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.story_Box}>
        <Box className={classes.cup_Box}>
          <Box component={"img"} src={cup} className={classes.cup_Image} />
        </Box>
        <Box>
          <Box
            component={"img"}
            src={every_cup}
            className={classes.every_cup}
          />
        </Box>
        <Box className={classes.bean_Box}>
          <Box component={"img"} src={bean} className={classes.bean_Image} />
        </Box>
      </Box>
    </>
  );
};

export default Section6Th;
