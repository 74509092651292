import React from "react";
import { Box } from "@mui/material";
import PostCard from "../BlogSection/PostCard";
import PostCardSkeleton from "../../Skelton/PostCardSkelton";
import { useSelector } from "react-redux";

const BlogSection = ({ setCurrentId }) => {
  const posts = useSelector((state) => state.posts);

  return (
    <>
      {posts.length > 0 ? (
        <>
          <Box display={"flex"} justifyContent={"flex-start"} flexWrap={"wrap"}>
            {posts.map((post) => (
              <Box key={post._id}>
                <PostCard post={post} setCurrentId={setCurrentId} />
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <>
          <PostCardSkeleton />
        </>
      )}
    </>
  );
};

export default BlogSection;
