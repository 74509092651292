import React from "react";
import { Box, Skeleton } from "@mui/material";

const PostCardSkeleton = () => {
  return (
    <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
      {[...Array(6)].map((item, index) => (
        <Box key={index} sx={{ m: 5, width: "400px", height: "400px" }}>
          <Skeleton
            variant="rectangular"
            width={400}
            height={300}
            sx={{ borderRadius: "10px" }}
          />

          <Skeleton variant="text" width={300} height={50} />
          <Skeleton variant="text" width={150} />
          <Skeleton variant="text" width={200} />
          <Skeleton variant="rounded" width={150} height={25} sx={{ mt: 2 }} />
        </Box>
      ))}
    </Box>
  );
};

export default PostCardSkeleton;
