import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  // Define your styles here
  root: {
    color: "white",
    padding: "0px",
  },

  /*** ourCoffee_Nicaragua ***/
  ////////////////////////////
  ourCoffee_Nicaragua: {
    "@media (min-width:0px)": {
      display: "block",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:600px)": { fontSize: "20px" },
    "@media (min-width:900px)": { fontSize: "20px" },
    "@media (min-width:1200px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  /*** Nicaragua_Map ***/
  ///////////////////
  map: {
    "@media (min-width:0px)": {
      width: "50%",
      marginLeft: "40%",
      marginTop: "-35%",
    },
    "@media (min-width:600px)": {
      width: "50%",
      marginLeft: "40%",
      marginTop: "-35%",
    },
    "@media (min-width:900px)": {
      width: "40%",
      height: "400px",
      marginLeft: "55%",
      marginTop: "-40%",
    },
    "@media (min-width:1200px)": {
      width: "40%",
      height: "500px",
      marginLeft: "50%",
      marginTop: "-40%",
    },
    "@media (min-width:1536px)": {
      width: "40%",
      height: "600px",
      marginLeft: "50%",
      marginTop: "-40%",
    },
  },
  /*** Nicaragua ***/
  ///////////////////

  nicaragua: {
    "@media (min-width:0px)": {
      width: "100%",
      marginLeft: "-25%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginLeft: "-25%",
    },
    "@media (min-width:900px)": {
      width: "55%",
      float: "left",
      height: "400px",
      marginLeft: "-10%",
    },
    "@media (min-width:1200px)": {
      width: "45%",
      float: "left",
      height: "500px",
      marginLeft: "-5%",
    },
    "@media (min-width:1536px)": {
      width: "45%",
      float: "left",
      height: "600px",
      marginLeft: "-5%",
    },
  },

  /*** ourCoffee_Guatemala ***/
  ////////////////////////////
  ourCoffee_Guatemala: {
    "@media (min-width:0px)": {
      display: "block",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:600px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "25%",
    },
    "@media (min-width:900px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "25%",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "25%",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "25%",
    },
  },
  /*** Guatemala_Map ***/
  ///////////////////
  map_2: {
    "@media (min-width:0px)": {
      width: "50%",
      marginBottom: "-85%",
      marginLeft: "-50%",
      zIndex: 2,
      position: "relative",
      diaplay: "flex",
    },
    "@media (min-width:600px)": {
      width: "50%",
      marginBottom: "-85%",
      marginLeft: "-50%",
      zIndex: 2,
      position: "relative",
      diaplay: "flex",
    },
    "@media (min-width:900px)": {
      width: "40%",
      height: "500px",
      marginLeft: "-20%",
      marginRight: "0%",
      marginBottom: "0%",
    },
    "@media (min-width:1200px)": {
      width: "40%",
      height: "550px",
      marginLeft: "-20%",
      marginRight: "0%",
      marginBottom: "0%",
    },
    "@media (min-width:1536px)": {
      width: "40%",
      height: "550px",
      marginLeft: "-20%",
      marginRight: "0%",
      marginBottom: "0%",
    },
  },
  /*** Guatemala ***/
  ///////////////////

  guatemala: {
    "@media (min-width:0px)": {
      width: "90%",
      marginLeft: "17%",
    },
    "@media (min-width:600px)": {
      width: "90%",
      marginLeft: "17%",
    },
    "@media (min-width:900px)": {
      width: "40%",
      height: "400px",
      marginRight: "-20%",
      marginLeft: "20%",
    },
    "@media (min-width:1200px)": {
      width: "40%",
      height: "500px",
      marginRight: "-20%",
      marginLeft: "20%",
    },
    "@media (min-width:1536px)": {
      width: "40%",
      height: "600px",
      marginRight: "-20%",
      marginLeft: "20%",
    },
  },
  /*** ourCoffee_Ethiopia ***/
  ////////////////////////////
  ourCoffee_Ethiopia: {
    "@media (min-width:0px)": {
      display: "block",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:600px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:900px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  /*** Ethiopia_Map ***/
  ///////////////////
  map_3: {
    "@media (min-width:0px)": {
      width: "70%",
      marginLeft: "35%",
      paddingRight: "0%",
      marginTop: "-35%",
    },
    "@media (min-width:600px)": {
      width: "70%",
      marginLeft: "35%",
      paddingRight: "0%",
      marginTop: "-35%",
    },
    "@media (min-width:900px)": {
      width: "40%",
      height: "450px",
      marginLeft: "20%",
      paddingRight: "0%",
    },
    "@media (min-width:1200px)": {
      width: "40%",
      height: "600px",
      marginLeft: "25%",
      paddingRight: "0%",
    },
    "@media (min-width:1536px)": {
      width: "40%",
      height: "600px",
      marginLeft: "25%",
      paddingRight: "0%",
    },
  },
  /*** Ethiopia ***/
  ///////////////////

  ethiopia: {
    "@media (min-width:0px)": {
      width: "100%",
      marginLeft: "-30%",
      marginTop: "10%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginLeft: "-30%",
      marginTop: "10%",
    },
    "@media (min-width:900px)": {
      width: "40%",
      height: "400px",
      marginLeft: "-5%",
      marginRight: "0%",
    },
    "@media (min-width:1200px)": {
      width: "40%",
      height: "500px",
      marginLeft: "-5%",
      marginRight: "0%",
    },
    "@media (min-width:1536px)": {
      width: "40%",
      height: "550px",
      marginLeft: "-5%",
      marginRight: "0%",
    },
  },
  espresso_title: {
    "@media (min-width:0px)": {
      width: "100%",
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingTop: "5%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
  },
  espresso_blends_box: {
    "@media (min-width:0px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      position: "relative",
      display: "flex",
    },
    "@media (min-width:600px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      position: "relative",
      display: "flex",
    },
    "@media (min-width:900px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      position: "relative",
      display: "flex",
    },
    "@media (min-width:1200px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
      marginBottom: "-10%",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      position: "relative",
      display: "flex",
      zIndex: 2,
    },
    "@media (min-width:1536px)": {
      paddingTop: "10%",
      paddingLeft: "35%",
      paddingRight: "0%",
      alignContent: "space-around",
      alignItems: "center",
      marginBottom: "-10%",
      position: "relative",
      display: "flex",
      zIndex: 2,
      gap: "10%",
    },
  },
  cremaImg: {
    "@media (min-width:0px)": {
      width: "50%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
  blendsImg: {
    "@media (min-width:0px)": {
      width: "50%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
});
