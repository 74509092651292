import { Box } from "@mui/material";
import React from "react";
import outline from "../../../../assets/outline.png";
import right from "../../../../assets/upperHand-l.png";
import Ready_title from "../../../../assets/ready_title.png";
import elevate from "../../../../assets/elevate.png";
import bean from "../../../../assets/bean.png";
//import "./style.css";
import { useStyles } from "./style";

const Section2Nd = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.second_section_Box_Title}>
        <Box sx={{ textAlign: "center", paddingY: "20px" }}>
          <Box
            component={"img"}
            src={Ready_title}
            className={classes.second_section_title}
          />
        </Box>
      </Box>
      {/********* PC ****************/}
      <Box className={classes.second_section_Box_PC}>
        <Box>
          <Box
            component={"img"}
            src={elevate}
            className={classes.second_section_elevate}
          />
        </Box>
        <Box>
          <Box
            component={"img"}
            src={right}
            sx={{ transform: "rotate(350deg)" }}
            className={classes.second_section_right}
          />{" "}
        </Box>
      </Box>
      {/************ Mobile ****************/}
      <Box className={classes.second_section_Box_Mobile}>
        <Box>
          <Box
            component={"img"}
            src={right}
            sx={{ transform: "rotate(350deg)" }}
            className={classes.second_section_right}
          />{" "}
        </Box>
        <Box>
          <Box
            component={"img"}
            src={elevate}
            className={classes.second_section_elevate}
          />
        </Box>
      </Box>
      <Box className={classes.second_section_bean}>
        <Box
          component={"img"}
          src={bean}
          className={classes.second_section_bean_img}
        />
      </Box>
      <Box>
        <Box
          component={"img"}
          src={outline}
          className={classes.second_section_outline}
        />
      </Box>
    </>
  );
};

export default Section2Nd;
