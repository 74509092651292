import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  // Define your styles here

  Brewed_With_Heritage: {
    width: "100%",
    "@media (min-width:0px)": { paddingLeft: "8%", paddingRight: "8%" },
    "@media (min-width:600px)": { paddingLeft: "11%", paddingRight: "11%" },
    "@media (min-width:900px)": { paddingLeft: "11%", paddingRight: "11%" },
    "@media (min-width:1200px)": { paddingLeft: "11%", paddingRight: "11%" },
    "@media (min-width:1536px)": { paddingLeft: "11%", paddingRight: "11%" },
  },
  grandImage: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "90%",
      marginTop: "3%",
      position: "relative",
      zIndex: 2,
      marginLeft: "30%",
    },
    "@media (min-width:600px)": {
      width: "90%",
      marginTop: "3%",
      position: "relative",
      zIndex: 2,
      marginLeft: "30%",
    },
    "@media (min-width:900px)": {
      width: "90%",
      marginTop: "3%",
      position: "relative",
      zIndex: 2,
      marginLeft: "10%",
    },
    "@media (min-width:1200px)": {
      width: "90%",
      marginTop: "-7%",
      position: "relative",
      zIndex: 2,
      marginLeft: "35%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      marginTop: "-7%",
      position: "relative",
      zIndex: 2,
      marginLeft: "50%",
    },
  },
  enreq_text: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
      marginTop: "-0%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-10%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginTop: "-0%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-10%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      marginTop: "-0%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-35%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      marginTop: "-7%",
      position: "relative",
      zIndex: 0,
      marginLeft: "0%",
    },
    "@media (min-width:1536px)": {
      width: "110%",
      marginTop: "-7%",
      position: "relative",
      zIndex: 0,
      marginLeft: "20%",
    },
  },
  took_text: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "90%",
      marginTop: "-10%",
      position: "relative",
      zIndex: 0,
      marginLeft: "0%",
      marginBottom: "0%",
      transform: "rotate(5deg)",
    },
    "@media (min-width:600px)": {
      width: "80%",
      marginTop: "-10%",
      position: "relative",
      zIndex: 0,
      marginLeft: "0%",
      marginBottom: "0%",
      transform: "rotate(-5deg)",
    },
    "@media (min-width:900px)": {
      width: "60%",
      marginTop: "-18%",
      position: "relative",
      zIndex: 0,
      marginLeft: "14%",
      marginBottom: "0%",
      transform: "rotate(3deg)",
    },
    "@media (min-width:1200px)": {
      width: "60%",
      marginTop: "-18%",
      position: "relative",
      zIndex: 0,
      marginLeft: "22%",
      marginBottom: "0%",
      transform: "rotate(0deg)",
    },
    "@media (min-width:1536px)": {
      width: "60%",
      marginTop: "-19%",
      position: "relative",
      zIndex: 0,
      marginLeft: "8%",
      marginBottom: "0%",
      transform: "rotate(-3deg)",
    },
  },

  curve_box: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "90%",
      marginTop: "3%",
      position: "relative",
      zIndex: 2,
      marginLeft: "15%",
      display: "none",
    },
    "@media (min-width:600px)": {
      width: "90%",
      marginTop: "3%",
      position: "relative",
      zIndex: 2,
      marginLeft: "10%",
      display: "none",
    },
    "@media (min-width:900px)": {
      width: "50%",
      marginLeft: "-6%",
      transform: "rotate(15deg)",
      marginTop: "-25%",
      marginBottom: "20px",
      display: "block",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      marginLeft: "-25%",
      transform: "rotate(10deg)",
      marginTop: "-25%",
      marginBottom: "20px",
      display: "block",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      marginLeft: "-30%",
      transform: "rotate(10deg)",
      marginTop: "-20%",
      display: "block",
    },
  },
  about_text: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "90%",
      marginTop: "-35%",
      position: "relative",
      zIndex: 0,
      marginLeft: "0%",
    },
    "@media (min-width:600px)": {
      width: "90%",
      marginTop: "-35%",
      position: "relative",
      zIndex: 0,
      marginLeft: "0%",
    },
    "@media (min-width:900px)": {
      width: "90%",
      marginTop: "-60%",
      position: "relative",
      zIndex: 0,
      marginLeft: "0%",
    },
    "@media (min-width:1200px)": {
      width: "90%",
      marginTop: "-60%",
      position: "relative",
      zIndex: 0,
      marginLeft: "0%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      marginTop: "-90%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-170%",
    },
  },
  emilioImage: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "90%",
      marginTop: "-15%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-40%",
    },
    "@media (min-width:600px)": {
      width: "90%",
      marginTop: "-15%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-40%",
    },
    "@media (min-width:900px)": {
      width: "90%",
      marginTop: "-30%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-40%",
    },
    "@media (min-width:1200px)": {
      width: "90%",
      marginTop: "-30%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-40%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      marginTop: "-45%",
      position: "relative",
      zIndex: 0,
      marginLeft: "-218%",
    },
  },
});
