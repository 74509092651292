import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  // Define your styles here
  root: {
    color: "white",
    padding: "0px",
  },

  espresso_title: {
    "@media (min-width:0px)": {
      width: "100%",
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingTop: "5%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      paddingLeft: "20%",
      paddingRight: "20%",
      paddingTop: "5%",
    },
  },
  espresso_blends_box: {
    "@media (min-width:0px)": {
      paddingTop: "4%",
      paddingLeft: "0%",
      paddingRight: "0%",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      gap: "2%",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    "@media (min-width:600px)": {
      paddingTop: "4%",
      paddingLeft: "0%",
      paddingRight: "0%",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      gap: "2%",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    "@media (min-width:900px)": {
      paddingTop: "4%",
      paddingLeft: "0%",
      paddingRight: "0%",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      gap: "2%",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    "@media (min-width:1200px)": {
      paddingTop: "4%",
      paddingLeft: "0%",
      paddingRight: "0%",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      gap: "2%",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    "@media (min-width:1536px)": {
      paddingTop: "10%",
      paddingLeft: "0%",
      paddingRight: "0%",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "-10%",
      zIndex: 2,
      gap: "10px",
      justifyContent: "space-between",
    },
  },
  cremaImg: {
    "@media (min-width:0px)": {
      width: "60%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:600px)": {
      width: "60%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:900px)": {
      width: "25%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1200px)": {
      width: "20%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1536px)": {
      width: "20%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
  blendsImg: {
    "@media (min-width:0px)": {
      width: "60%",
      paddingLeft: "0%",
      paddingRight: "0%",
      marginTop: "2%",
    },
    "@media (min-width:600px)": {
      width: "60%",
      paddingLeft: "0%",
      paddingRight: "0%",
      marginTop: "2%",
    },
    "@media (min-width:900px)": {
      width: "25%",
      paddingLeft: "0%",
      paddingRight: "0%",
      marginTop: "2%",
    },
    "@media (min-width:1200px)": {
      width: "20%",
      paddingLeft: "0%",
      paddingRight: "0%",
      marginTop: "0%",
    },
    "@media (min-width:1536px)": {
      width: "20%",
      paddingLeft: "0%",
      paddingRight: "0%",
      marginTop: "0%",
    },
  },

  cup: {
    "@media (min-width:0px)": {
      width: "15%",
      marginTop: "-100%",
      marginLeft: "75%",
    },
    "@media (min-width:600px)": {
      width: "15%",
      marginTop: "-100%",
      marginLeft: "75%",
    },
    "@media (min-width:900px)": {
      width: "15%",
      marginTop: "-100%",
      marginLeft: "75%",
    },
    "@media (min-width:1200px)": {
      width: "15%",
      marginTop: "-100%",
      marginLeft: "75%",
    },
    "@media (min-width:1536px)": {
      width: "15%",
      marginTop: "-100%",
      marginLeft: "75%",
    },
  },

  outline: {
    "@media (min-width:0px)": {
      width: "100%",
      marginTop: "-5%",
      position: "relative",
      zIndex: 1,
      hight: "20px",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginTop: "-5%",
      position: "relative",
      zIndex: 1,
      hight: "20px",
    },
    "@media (min-width:900px)": {
      width: "100%",
      marginTop: "-5%",
      position: "relative",
      zIndex: 1,
      hight: "20px",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      marginTop: "-5%",
      position: "relative",
      zIndex: 1,
      hight: "20px",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      marginTop: "-5%",
      position: "relative",
      zIndex: 1,
    },
  },
  crema_blend: {
    "@media (min-width:0px)": {
      width: "100%",
    },
    "@media (min-width:600px)": {
      width: "100%",
    },
    "@media (min-width:900px)": {
      width: "40%",
    },
    "@media (min-width:1200px)": {
      width: "35%",
    },
    "@media (min-width:1536px)": {
      width: "35%",
    },
  },
  bean: {
    "@media (min-width:0px)": {
      width: "30%",
      height: "180px",
      marginLeft: "25%",
      marginBottom: "8%",
    },
    "@media (min-width:600px)": {
      width: "50%",
      height: "250px",
      marginLeft: "25%",
      marginBottom: "8%",
    },
    "@media (min-width:900px)": {
      width: "15%",
      height: "180px",
      marginLeft: "0%",
    },
    "@media (min-width:1200px)": {
      width: "10%",
      height: "200px",
      marginLeft: "0%",
    },
    "@media (min-width:1536px)": {
      width: "10%",
      height: "280px",
      marginLeft: "0%",
    },
  },
  brooklyn_blend: {
    "@media (min-width:0px)": {
      width: "100%",
    },
    "@media (min-width:600px)": {
      width: "100%",
    },
    "@media (min-width:900px)": {
      width: "40%",
    },
    "@media (min-width:1200px)": {
      width: "35%",
    },
    "@media (min-width:1536px)": {
      width: "35%",
    },
  },
});
