import { Box } from "@mui/material";
import React from "react";
import sip from "../../../../assets/every_sip.png";
import upperHand from "../../../../assets/upperHand.png";
import upperHand_up from "../../../../assets/upperHand-up.png";
import outline from "../../../../assets/outline.png";
import { useStyles } from "./style";

const Section8Th = () => {
  const classes = useStyles();
  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box component={"img"} src={upperHand} className={classes.upperHand} />

        <Box
          component={"img"}
          src={sip}
          sx={{
            paddingTop: "10%",
            marginLeft: "-15%",
          }}
          className={classes.sipImage}
        />
        <Box
          component={"img"}
          src={upperHand_up}
          sx={{ paddingTop: "20%", marginLeft: "-20%" }}
          className={classes.upperHand_up}
        />
      </Box>

      <Box
        component={"img"}
        src={outline}
        className={classes.second_section_outline}
      />
    </>
  );
};

export default Section8Th;
