import { Box } from "@mui/material";
import React from "react";
import crafting from "../../../../assets/crafting.png";
import left from "../../../../assets/upperHand-left.png";
import bean_hand from "../../../../assets/bean_hand.png";
import { useStyles } from "./style";

const Section3Rd = () => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ height: "10vh" }} />
      <Box className={classes.third_section_Box}>
        <Box
          component={"img"}
          src={crafting}
          className={classes.third_section_crafting_img}
        />
        <Box
          component={"img"}
          src={left}
          className={classes.third_section_crafting_left}
        />
        <Box
          component={"img"}
          src={bean_hand}
          className={classes.third_section_crafting_hand}
        />
      </Box>
    </>
  );
};

export default Section3Rd;
