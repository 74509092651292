import React from "react";
import { Box } from "@mui/material";
import CarouselComponent from "../../../../components/Shared/Carousel/Carousel";
import Slide1St from "./Slide1St";
import Slide2Nd from "./Slide2Nd";
import Slide3Rd from "./Slide3Rd";
import Slide4Th from "./Slide4Th";

const Section5TH = () => {
  const items = [<Slide1St />, <Slide2Nd />, <Slide3Rd />, <Slide4Th />];
  return (
    <>
      <Box>
        <CarouselComponent items={items} />
      </Box>
    </>
  );
};

export default Section5TH;
