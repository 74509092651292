import axios from "axios";

const url = "https://elevacoffee-975ca7ed9196.herokuapp.com/api";

export const fetchPosts = () => axios.get(`${url}/posts`);
export const fetchPost = (id) => axios.get(`${url}/posts/${id}`);
export const createPost = (newPost) => axios.post(`${url}/posts/new`, newPost);
export const likePost = (id) => axios.patch(`${url}/${id}/likePost`);
export const updatePost = (id, updatedPost) =>
  axios.patch(`${url}/${id}`, updatedPost);
export const deletePost = (id) => axios.delete(`${url}/${id}`);

export const fetchProducts = () => axios.get(`${url}/products`);
export const fetchProduct = (id) => axios.get(`${url}/products/${id}`);
export const createProduct = (newProduct) =>
  axios.post(`${url}/products/new`, newProduct);
export const updateProduct = (id, updatedProduct) =>
  axios.patch(`${url}/products/${id}`, updatedProduct);
export const deleteProduct = (id) => axios.delete(`${url}/products/${id}`);
