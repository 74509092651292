import React from "react";
import { Box, Skeleton } from "@mui/material";

const PostCardSkeleton = () => {
  return (
    <Box display={"flex"} justifyContent={"space-evenly"} flexWrap={"wrap"}>
      {[...Array(3)].map((item, index) => (
        <Box key={index} sx={{ m: 5, width: "400px", height: "400px" }}>
          <Skeleton variant="rectangular" width={350} height={300} sx={{}} />

          <Box display={"flex"} gap={12}>
            <Box>
              <Skeleton variant="text" width={200} height={50} />
            </Box>
            <Box>
              <Skeleton variant="text" width={50} height={50} />
            </Box>
          </Box>
          <Box display={"flex"} gap={1}>
            <Box>
              <Skeleton variant="circular" width={20} height={20} />
            </Box>
            <Box>
              <Skeleton variant="text" width={150} />
            </Box>
          </Box>
          <Skeleton variant="text" width={178} />
          <Box display={"flex"} gap={3}>
            <Box>
              <Skeleton variant="text" width={178} />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={145}
                height={35}
                sx={{ mt: -2 }}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PostCardSkeleton;
