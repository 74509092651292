import { Box } from "@mui/material";
import React from "react";
import sus from "../../../../assets/Home/7th/sustinability_quality_2.png";
import kid from "../../../../assets/Home/7th/eleva_students.png";
import { useStyles } from "./style";
import farm from "../../../../assets/Home/7th/emilio_with_farmers.png";
import eleva_text from "../../../../assets/Home/7th/eleva_coffee_known_for _it's_sustinable.png";
import eleva_text2 from "../../../../assets/Home/7th/this_dedication_not_only_enhances.png";
import curve from "../../../../assets/Home/7th/curve.png";
const Section7Th = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Sus_Box}>
        <Box className={classes.Sus_Box_Container}>
          <Box component={"img"} src={sus} className={classes.Sus_Image} />
        </Box>
        <Box className={classes.Farm_Box_Container}>
          <Box
            component={"img"}
            src={farm}
            sx={{ zIndex: 2, position: "relative", marginLeft: "10%" }}
            className={classes.Farm_Image}
          />
        </Box>
      </Box>
      <Box className={classes.kid_Box}>
        <Box className={classes.Kid_Box_Container}>
          <Box component={"img"} src={kid} className={classes.Kid_Image} />
        </Box>
        <Box className={classes.text_Box_Container}>
          <Box
            component={"img"}
            src={eleva_text}
            className={classes.text_Image}
            sx={{ zIndex: 0 }}
          />
        </Box>
      </Box>
      <Box className={classes.text_2_Box}>
        <Box className={classes.text_2_Box_Container}>
          <Box
            component={"img"}
            src={eleva_text2}
            sx={{ marginTop: "-7%", zIndex: 0 }}
            className={classes.text_2_Image}
          />
        </Box>
        <Box className={classes.curve_Box_Container}>
          <Box className={classes.curve_Image} component={"img"} src={curve} />
        </Box>
      </Box>{" "}
    </>
  );
};

export default Section7Th;
