import React from "react";
import { useStyles } from "./style";
import { Box } from "@mui/material";
import emilio from "../../../../assets/Home/9th/emilio_baltodano_founder.png";
import faisal from "../../../../assets/Home/9th/ahmed_faisal_board_member.png";
import alvero from "../../../../assets/Home/9th/alvero_baltodano_board_member.png";
import ashton from "../../../../assets/Home/9th/ashton_belly_eleva_president.png";
import heard from "../../../../assets/Home/9th/glenn_heard_ceo_eleva_mena.png";
import Team from "../../../../assets/Home/9th/eleva_team_title.png";
import Dual_Curve from "../../../../assets/Home/9th/dual_curve.png";
const data = [
  { id: 1, img: emilio, alt: "emilio_baltodano_founder" },
  { id: 2, img: faisal, alt: "ahmed_faisal_board_member" },
  { id: 3, img: alvero, alt: "alvero_baltodano_board_member" },
  { id: 4, img: ashton, alt: "ashton_belly_eleva_president" },
  { id: 5, img: heard, alt: "glenn_heard_ceo_eleva_mena" },
];
const Section9Th = () => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ marginY: "5%" }}>
        <Box component={"img"} src={Team} className={classes.Team_title} />
      </Box>
      <Box display={"flex"} justifyContent={"flex-start"} flexWrap={"wrap"}>
        <Box className={classes.Team_Box}>
          {data.map((item) => {
            return (
              <Box
                key={item.id}
                component={"img"}
                src={item.img}
                alt={item.alt}
                className={classes.Team_image}
              />
            );
          })}
          <Box
            component={"img"}
            src={Dual_Curve}
            alt=""
            className={classes.Team_image}
            sx={{ paddingLeft: "20px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Section9Th;
