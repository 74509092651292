import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import PostDetailSection from "../PostDetailSection/PostDetailSection";

const PostDetails = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `https://elevacoffee-975ca7ed9196.herokuapp.com/api/posts/${id}`
        );
        setPost(response.data);
      } catch (error) {
        console.log("error detching ", error);
      }
    };
    fetchPost();
  }, [id]);

  if (!post) return <div>Loading...</div>;
  return (
    <Box
      sx={{
        marginTop: { xl: "10%", lg: "10%", md: "10%", sm: "10%", xs: "15%" },
      }}
    >
      <Box>
        <Box>
          <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
            <Typography variant="h2">{post.title}</Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexWrap={"wrap"}
            mb={10}
          >
            <Box
              component={"img"}
              src={post.selectedFile}
              width={"100%"}
              border={"1px solid gold"}
              sx={{
                clipPath: "inset(2% 5% 30% 5% round 10px)",
                height: {
                  xl: "90vh",
                  lg: "90vh",
                  md: "60vh",
                  sm: "100%",
                  xs: "100%",
                },
              }}
              loading="lazy"
            />
          </Box>
          <PostDetailSection
            subtitle={post.subtitle_1st}
            content={post.content_1st}
            sectionImg={post.selectedFile_1st}
          />

          <PostDetailSection
            left
            subtitle={post.content_2nd}
            content={post.subtitle_2nd}
            sectionImg={post.selectedFile_2nd}
          />

          <PostDetailSection
            subtitle={post.subtitle_3rd}
            content={post.content_3rd}
            sectionImg={post.selectedFile_3rd}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PostDetails;
