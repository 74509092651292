import React from "react";
import { Box } from "@mui/material";
import LogoImg from "../../assets/Navbar/logo_eleva_coffee.png";

import { NavLink } from "react-router-dom";

const Logo = () => {
  return (
    <>
      <Box component={"div"}>
        <NavLink to="/">
          <Box component={"img"} src={LogoImg} alt="Logo" width={"120px"} />
        </NavLink>
      </Box>
    </>
  );
};

export default Logo;
