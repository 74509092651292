import React from "react";
import { Navigate, Route, Routes } from "react-router";
import ProtectedRoute from "./components/ProtectedRoute";
import {
  Archive,
  Dashboard,
  Landing,
  OurCoffee,
  Store,
  WholeSale,
} from "./pages/";
import MainLayout from "./layout/MainLayout";
import LAYOUTWITHNAV from "./layout/Layout_&_Nav";
import PostDetails from "./components/Shared/BlogSection/PostDetails";
const Router = () => {
  //const Auth = useAuth();
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {/* Static-Routes */}
          <Route path="/" element={<LAYOUTWITHNAV />}>
            <Route index element={<Landing />} />
            <Route path="eleva-coffee-selection" element={<OurCoffee />} />
            <Route
              path="/eleva-coffee-lounge"
              element={"eleva-coffee-lounge"}
            />
            <Route path="/eleva-wholesale" exact element={<WholeSale />} />
            <Route path="/terms-of-use" exact element={"<Terms />"} />
            <Route path="/privacy-statement" exact element={"<Privacy />"} />
            {/* Product-Routes */}
            <Route path="/eleva-coffee-store" element={<Store />} />

            {/* Blog-Routes */}
            <Route path="/eleva-latest-news" element={<Archive />} />
            <Route path="/eleva-latest-news/:id" element={<PostDetails />} />
          </Route>
          <Route path="/user/:userId" component={"UserProfile"} />
          {/* Auth-Routes*/}
          <Route path="/login" element="Login" />
          Protected-Routes
          <Route path="/dashboard" element={<Dashboard />} />
          <Route element={<ProtectedRoute />} Component={"SideNav"}>
            {/* Protected-Routes*/}
            {/* Products-Create-Update-Delete */}
            <Route path="create-new-product/:id?" element="CProduct" />
            <Route path="update-product/:id?" element="UProduct" />
            <Route path="delete-product/:id?" element="DProduct" />
            {/* Bolg-Create-Update-Delete*/}
            <Route path="create-new-post/:id" element="CPost" />
            <Route path="update-post/:id" element="UPost" />
            <Route path="delete-post/:id" element="DPost" />
          </Route>
          {/* Error-Routes */}
          <Route path="/page-not-found" element="404 | Page Not Found" />
          <Route path="*" element=<Navigate to="/page-not-found" replace /> />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
