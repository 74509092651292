import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  // Define your styles here
  root: {
    color: "white",
    padding: "0px",
  },

  cold_box: {
    "@media (min-width:0px)": { marginLeft: "0%" },
    "@media (min-width:600px)": { marginLeft: "-10%" },
    "@media (min-width:900px)": { marginLeft: "-15%" },
    "@media (min-width:1200px)": { marginLeft: "-10%" },
    "@media (min-width:1536px)": { marginLeft: "-5%" },
  },
  cold_img: {
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "100%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": { width: "100%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  dual_box: {
    "@media (min-width:0px)": { marginLeft: "0%", marginTop: "-20%" },
    "@media (min-width:600px)": {
      marginLeft: "-50%",
      marginTop: "0%",
      marginRight: "20%",
    },
    "@media (min-width:900px)": {
      marginLeft: "-40%",
      marginTop: "5%",
      marginRight: "0%",
    },
    "@media (min-width:1200px)": { marginLeft: "-27%", marginRight: "0%" },
    "@media (min-width:1536px)": { marginLeft: "-25%" },
  },
  dual_img: {
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "110%" },
    "@media (min-width:900px)": { width: "70%" },
    "@media (min-width:1200px)": { width: "80%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  special_box: {
    "@media (min-width:0px)": { marginLeft: "0%" },
    "@media (min-width:600px)": { marginLeft: "15%" },
    "@media (min-width:900px)": {
      marginLeft: "-15%",
      marginTop: "10%",
      marginRight: "10%",
    },
    "@media (min-width:1200px)": { marginLeft: "-5%" },
    "@media (min-width:1536px)": { marginLeft: "-10%", marginRight: "0%" },
  },
  special_img: {
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "120%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": { width: "110%" },
    "@media (min-width:1536px)": { width: "120%" },
  },
  curve_box: {
    "@media (min-width:0px)": { marginLeft: "0%", display: "none" },
    "@media (min-width:600px)": { marginLeft: "0%", display: "none" },
    "@media (min-width:900px)": { marginLeft: "0%", display: "none" },
    "@media (min-width:1200px)": { marginLeft: "0%", display: "flex" },
    "@media (min-width:1536px)": { marginLeft: "10%", display: "flex" },
  },
  curve_img: {
    "@media (min-width:0px)": { width: "50%" },
    "@media (min-width:600px)": { width: "50%" },
    "@media (min-width:900px)": { width: "80%" },
    "@media (min-width:1200px)": { width: "100%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  nd_box: {
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "-10%",
    zIndex: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "@media (min-width:0px)": {
      gap: "14%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:600px)": {
      gap: "14%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:900px)": {
      gap: "14%",
      paddingLeft: "30%",
      paddingRight: "30%",
    },
    "@media (min-width:1200px)": { gap: "14%" },
    "@media (min-width:1536px)": {
      gap: "15%",
      paddingLeft: "30%",
      paddingRight: "30%",
    },
  },
  colombia_img: {
    "@media (min-width:0px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:900px)": {
      width: "50%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1200px)": {
      width: "130%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1536px)": {
      width: "145%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
  dark_img: {
    "@media (min-width:0px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:900px)": {
      width: "50%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1200px)": {
      width: "130%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
    "@media (min-width:1536px)": {
      width: "145%",
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
  cup_img: {
    "@media (min-width:0px)": {
      width: "10%",
      marginTop: "0%",
      marginLeft: "75%",
      display: "none",
    },
    "@media (min-width:600px)": {
      display: "none",
      width: "10%",
      marginTop: "0%",
      marginLeft: "75%",
    },
    "@media (min-width:900px)": {
      width: "10%",
      marginTop: "0%",
      marginLeft: "75%",
      display: "flex",
    },
    "@media (min-width:1200px)": {
      width: "10%",
      marginTop: "0%",
      marginLeft: "75%",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      width: "10%",
      marginTop: "-0%",
      marginLeft: "75%",
      display: "flex",
    },
  },
  mountain_img: {
    "@media (min-width:0px)": {
      width: "100%",

      marginTop: "-5%",
      height: "250px",
      position: "relative",
    },
    "@media (min-width:600px)": {
      width: "100%",
      marginTop: "-5%",
      height: "450px",
      position: "relative",
    },
    "@media (min-width:900px)": {
      width: "100%",

      marginTop: "-5%",
      height: "450px",
      position: "relative",
    },
    "@media (min-width:1200px)": {
      width: "100%",

      marginTop: "-5%",
      height: "450px",
      position: "relative",
    },
    "@media (min-width:1536px)": {
      width: "100%",

      marginTop: "-5%",
      height: "450px",
      position: "relative",
    },
  },
});
