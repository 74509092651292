import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import P1ST from "../../../../assets/wholesale/p-1.png";
import P2ND from "../../../../assets/wholesale/p-2.png";
import P3RD from "../../../../assets/wholesale/p-3.png";
import P5TH from "../../../../assets/wholesale/p-5.png";
import P6TH from "../../../../assets/wholesale/p-6.png";
import P7TH from "../../../../assets/wholesale/p-7.png";
import P8TH from "../../../../assets/wholesale/p-8.png";
import P9TH from "../../../../assets/wholesale/p-9.png";

const WholeSaleDisc = () => {
  const items = [
    { img: P1ST },
    { img: P2ND },
    { img: P3RD },
    { img: P5TH },
    { img: P6TH },
    { img: P7TH },
    { img: P8TH },
    { img: P9TH },
  ];
  return (
    <>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        marginX={"5%"}
        marginY={"5%"}
      >
        <Box>
          <Typography
            sx={{
              fontSize: {
                xl: "7rem",
                lg: "5rem",
                md: "5rem",
                sm: "5rem",
                xs: "4rem",
              },
              textAlign: {
                xl: "left",
                lg: "left",
                md: "left",
                sm: "left",
                xs: "left",
              },
              lineHeight: {
                xl: "6rem",
                lg: "5rem",
                md: "5rem",
                sm: "5rem",
                xs: "4rem",
              },
            }}
            fontWeight={"bold"}
          >
            ELEVA WHOLESALE
          </Typography>
        </Box>
        <Box
          sx={{
            paddingY: { xl: "2%", lg: "2%", md: "2%", sm: "2%", xs: "2%" },
            paddingX: { xl: "1%", lg: "1%", md: "1%", sm: "2%", xs: "2%" },
            textAlign: {
              xl: "left",
              lg: "left",
              md: "left",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xl: "21px",
                lg: "20px",
                md: "20px",
                sm: "20px",
                xs: "20px",
              },
            }}
          >
            We are proud to introduce our *NEW* wholesale program, where you
            will be getting the same delicious product from our coffee growing
            communities to your restaurant, cafe, co-working space, or office.
            Our passionate and knowledgeable team will be happy to work with you
            to make your coffee program a success, and you can receive training
            either on-site, or in our new location in Williamsburg, Brooklyn
            <br />
            <br />
            You will love our product, and we are sure your customers will too.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: {
            xl: "2%",
            lg: "2%",
            md: "2%",
            sm: "2%",
            xs: "2%",
          },
        }}
      >
        <>
          <Grid
            container
            xl={12}
            spacing={1}
            alignItems="center"
            justifyContent={"space-evenly"}
          >
            {items.map((item, index) => (
              <div key={index}>
                <Box
                  component={"img"}
                  sx={{
                    width: {
                      xl: "100px",
                      lg: "80px",
                      md: "80px",
                      sm: "100px",
                      xs: "80px",
                    },
                  }}
                  src={item.img}
                  alt=""
                />
              </div>
            ))}
          </Grid>
        </>
      </Box>
    </>
  );
};

export default WholeSaleDisc;
