import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  // Define your styles here

  Team_title: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "@media (min-width:0px)": { paddingLeft: "15%", paddingRight: "15%" },
    "@media (min-width:600px)": { paddingLeft: "35%", paddingRight: "35%" },
    "@media (min-width:900px)": { paddingLeft: "35%", paddingRight: "35%" },
    "@media (min-width:1200px)": { paddingLeft: "35%", paddingRight: "35%" },
    "@media (min-width:1536px)": { paddingLeft: "35%", paddingRight: "35%" },
  },

  Team_Box: {
    "@media (min-width:0px)": {
      paddingLeft: "5%",
      paddingRight: "5%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    "@media (min-width:600px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    "@media (min-width:900px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    "@media (min-width:1200px)": {
      paddingLeft: "0%",
      paddingRight: "0%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    "@media (min-width:1536px)": {
      paddingLeft: "15%",
      paddingRight: "15%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },

  Team_image: {
    width: "100%",
    "@media (min-width:0px)": { width: "30%" },
    "@media (min-width:600px)": { width: "30%" },
    "@media (min-width:900px)": { width: "30%" },
    "@media (min-width:1200px)": { width: "30%" },
    "@media (min-width:1536px)": {
      width: "30%",
    },
  },
});
