import React from "react";
import { Box, Container } from "@mui/material";
import ProductCard from "./ProductCard";
import ProductCardSkelton from "../../Skelton/ProductCardSkelton";
import { useSelector } from "react-redux";

//use Effect Method to Fetch All Products
const StoreSection = ({ blends, special, origin, setCurrentId }) => {
  const products = useSelector((state) => state.products);

  // eslint-disable-next-line

  //const single = products.filter((product) => product.tag === `single`);
  //const EspressoCoffee = products.filter((product) => product.tag === `blends`);
  //const SpecialCoffee = products.filter((product) => product.tag === `special`);

  return (
    <>
      {products.length > 0 ? (
        <Container maxWidth="xl">
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            flexWrap={"wrap"}
            gap={10}
          >
            {products.map((product) => (
              <>
                <Box key={product._id}>
                  <ProductCard
                    Category={product.Category}
                    Country={product.Country}
                    RoastType={product.RoastType}
                    CoffeeTaste={product.CoffeeTaste}
                    Price={product.Price}
                    ShopifyLink={product.ShopifyLink}
                    ProductFront={product.ProductFront}
                    ProductBack={product.ProductBack}
                    Tag={product.tag}
                    setCurrentId={setCurrentId}
                  />
                </Box>
              </>
            ))}
          </Box>
        </Container>
      ) : (
        <>
          <ProductCardSkelton />
        </>
      )}
    </>
  );
};

export default StoreSection;
