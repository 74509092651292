import React from "react";
import Carousel from "react-material-ui-carousel";
import { Grid, Box } from "@mui/material";
import "./carousel.css";

const CarouselComponent = ({ items }) => {
  return (
    <div className="carousel-container">
      <Carousel
        sx={{ width: "100%", height: "100%" }}
        interval={3000}
        animation="slide"
        duration={500}
        navButtonsAlwaysVisible
      >
        {items.map((item) => (
          <>
            <Grid
              container
              xl={12}
              spacing={1}
              alignItems="center"
              key={item.id}
              justifyContent={"center"}
            >
              <Box>{item}</Box>
            </Grid>
          </>
        ))}
      </Carousel>
    </div>
  );
};
export default CarouselComponent;
