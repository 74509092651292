import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  Sus_Box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    "@media (min-width:0px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "10%",
    },
    "@media (min-width:600px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "10%",
    },
    "@media (min-width:900px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "10%",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "10%",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "10%",
    },
  },
  Sus_Box_Container: {
    width: "50%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "50%" },
    "@media (min-width:900px)": { width: "50%" },
    "@media (min-width:1200px)": { width: "50%" },
    "@media (min-width:1536px)": { width: "50%" },
  },
  Sus_Image: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "100%" },
    "@media (min-width:900px)": { width: "120%" },
    "@media (min-width:1200px)": { width: "120%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  Farm_Box_Container: {
    width: "50%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "80%" },
    "@media (min-width:900px)": { width: "50%" },
    "@media (min-width:1200px)": { width: "50%" },
    "@media (min-width:1536px)": { width: "50%" },
  },
  Farm_Image: {
    width: "50%",
    "@media (min-width:0px)": { width: "50%" },
    "@media (min-width:600px)": { width: "50%" },
    "@media (min-width:900px)": { width: "50%" },
    "@media (min-width:1200px)": { width: "50%" },
    "@media (min-width:1536px)": { width: "50%" },
  },

  kid_Box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    "@media (min-width:0px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "-10%",
    },
    "@media (min-width:600px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "-10%",
    },
    "@media (min-width:900px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "-10%",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "-5%",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "-5%",
    },
  },
  Kid_Box_Container: {
    "@media (min-width:0px)": {
      width: "50%",
      marginTop: "-5%",
      display: "none",
    },
    "@media (min-width:600px)": {
      width: "30%",
      marginTop: "-5%",
      display: "none",
    },
    "@media (min-width:900px)": {
      width: "30%",
      marginTop: "-5%",
      display: "block",
    },
    "@media (min-width:1200px)": {
      width: "30%",
      marginTop: "-5%",
      display: "block",
    },
    "@media (min-width:1536px)": {
      width: "30%",
      marginTop: "-5%",
      display: "block",
    },
  },
  Kid_Image: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "100%" },
    "@media (min-width:900px)": { width: "100%" },
    "@media (min-width:1200px)": { width: "100%" },
    "@media (min-width:1536px)": { width: "100%" },
  },
  text_Box_Container: {
    width: "50%",
    "@media (min-width:0px)": {
      width: "100%",
      marginTop: "-2%",
    },
    "@media (min-width:600px)": { width: "100%" },
    "@media (min-width:900px)": { width: "60%", marginTop: "-2%" },
    "@media (min-width:1200px)": { width: "60%", marginTop: "-2%" },
    "@media (min-width:1536px)": { width: "60%", marginTop: "-2%" },
  },
  text_Image: {
    width: "100%",
    "@media (min-width:0px)": { width: "100%" },
    "@media (min-width:600px)": { width: "85%" },
    "@media (min-width:900px)": { width: "85%" },
    "@media (min-width:1200px)": { width: "85%" },
    "@media (min-width:1536px)": { width: "85%" },
  },

  text_2_Box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    "@media (min-width:0px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "5%",

      diaplay: "none",
    },
    "@media (min-width:600px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "5%",
      marginLeft: "15%",
      diaplay: "none",
    },
    "@media (min-width:900px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "5%",
      marginLeft: "0%",
      diaplay: "block",
    },
    "@media (min-width:1200px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "5%",
      marginLeft: "0%",
      diaplay: "block",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      marginTop: "5%",
      marginLeft: "0",
      diaplay: "block",
    },
  },
  text_2_Box_Container: {
    width: "50%",
    "@media (min-width:0px)": {
      width: "60%",
      marginTop: "-7%",
      marginRight: "-20%",
    },
    "@media (min-width:600px)": {
      width: "60%",
      marginTop: "-7%",
      marginRight: "-20%",
    },

    "@media (min-width:900px)": {
      width: "60%",
      marginTop: "-7%",
      marginLeft: "7%",
    },
    "@media (min-width:1200px)": {
      width: "60%",
      marginTop: "-7%",
      marginRight: "-20%",
    },
    "@media (min-width:1536px)": {
      width: "60%",
      marginTop: "-7%",
      marginLeft: "7%",
    },
  },
  text_2_Image: {
    width: "100%",
    "@media (min-width:0px)": { width: "20%", display: "none" },
    "@media (min-width:600px)": { width: "70%", display: "none" },
    "@media (min-width:900px)": { width: "70%", display: "inline" },
    "@media (min-width:1200px)": { width: "70%", display: "inline" },
    "@media (min-width:1536px)": { width: "70%", display: "inline" },
  },
  curve_Box_Container: {
    width: "50%",
    "@media (min-width:0px)": {
      width: "40%",
      marginTop: "-15%",
      diaplay: "none",
    },
    "@media (min-width:600px)": {
      width: "40%",
      marginTop: "-15%",
      diaplay: "block",
    },
    "@media (min-width:900px)": {
      width: "40%",
      marginTop: "-15%",
      diaplay: "block",
    },
    "@media (min-width:1200px)": {
      width: "40%",
      marginTop: "-15%",
      diaplay: "block",
    },
    "@media (min-width:1536px)": {
      width: "40%",
      marginTop: "-15%",
      diaplay: "flex",
    },
  },
  curve_Image: {
    width: "100%",
    "@media (min-width:0px)": { width: "0", diaplay: "none" },
    "@media (min-width:600px)": { width: "0%", diaplay: "none" },
    "@media (min-width:900px)": { width: "100%", diaplay: "block" },
    "@media (min-width:1200px)": { width: "100%", diaplay: "flex" },
    "@media (min-width:1536px)": { width: "100%", diaplay: "flex" },
  },
});
