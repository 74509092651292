import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  HeaderMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    paddingLeft: "120px",
    paddingRight: "120px",
    paddingTop: "32px",
    paddingBottom: "32px",
    gap: "1117px",
    position: "absolute",
    zIndex: "5",
  },
  HeaderContent: {
    height: "28px",
    top: "70px",
    left: "20px",
    gap: "100px",
    paddingTop: "45px",
  },
  HeaderLogoContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    paddingRight: "20%",
    marginTop: "-70px",
  },
  logo: {
    display: "flex",
    width: "100%",
    "@media (min-width:0px) ": {
      width: "100%",
      paddingLeft: "25%",
      paddingRight: "25%",
    },

    "@media (min-width:600px)": {
      width: "100%",
      paddingLeft: "30%",
      paddingRight: "30%",
    },
    "@media (min-width:900px)": {
      width: "100%",
      paddingLeft: "40%",
      paddingRight: "40%",
    },

    "@media (min-width:1200px)": { display: "none" },
    "@media (min-width:1536px)": { display: "none" },
  },

  AppBar: {
    zIndex: "1000",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "0%",
    "@media (min-width:0px)": {
      paddingLeft: "2%",
      paddingRight: "3%",
      height: "12vh",
    },
    "@media (min-width:600px)": {
      paddingLeft: "2%",
      paddingRight: "3%",
      height: "10vh",
    },
    "@media (min-width:900px)": {
      paddingLeft: "2%",
      paddingRight: "3%",
      height: "10vh",
    },
    "@media (min-width:1200px)": { display: "none" },
    "@media (min-width:1536px)": { display: "none" },
  },
  Nav: {
    textDecoration: "none",
    color: "#fff",
  },
}));
