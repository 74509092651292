import React, { useState, useEffect } from "react";
import { Paper, TextField, Box, Button } from "@mui/material";
import FileBase from "react-file-base64";
import { createProduct, updateProduct } from "../../../../actions/products";
import { useSelector, useDispatch } from "react-redux";

const ProductForm = ({ currentId, setCurrentId }) => {
  const [productData, setProductData] = useState({
    Category: "",
    Country: "",
    RoastType: "",
    CoffeeTaste: "",
    Price: "",
    ShopifyLink: "",
    ProductFront: "",
    ProductBack: "",
    tag: [],
  });
  const product = useSelector((state) =>
    currentId
      ? state.products.find((message) => message._id === currentId)
      : null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (product) setProductData(product);
  }, [product]);

  const clear = () => {
    setCurrentId(0);
    setProductData({
      Category: [],
      Country: "",
      RoastType: "",
      CoffeeTaste: "",
      Price: "",
      ShopifyLink: "",
      ProductFront: "",
      ProductBack: "",
      tag: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createProduct(productData));
      clear();
    } else {
      dispatch(updateProduct(currentId, productData));
      clear();
    }
  };

  return (
    <div>
      <Box display={"flex"} justifyContent={"flex-start"} gap={5}>
        <Paper sx={{ padding: "20px", borderRadius: "15px" }}>
          <h2>Create New Product</h2>

          <form
            onSubmit={handleSubmit}
            method="POST"
            enctype="multipart/form-data"
          >
            <TextField
              label="Category ..."
              fullWidth
              sx={{ my: "10px" }}
              value={productData.Category}
              onChange={(e) =>
                setProductData({ ...productData, Category: e.target.value })
              }
              name="title"
            />
            <TextField
              label="Country ..."
              fullWidth
              sx={{ my: "10px" }}
              value={productData.Country}
              onChange={(e) =>
                setProductData({ ...productData, Country: e.target.value })
              }
              name="Country"
            />
            <TextField
              label="Roast Type ..."
              fullWidth
              sx={{ my: "10px" }}
              value={productData.RoastType}
              onChange={(e) =>
                setProductData({ ...productData, RoastType: e.target.value })
              }
              name="RoastType"
            />
            <TextField
              label="CoffeeTaste ..."
              fullWidth
              sx={{ my: "10px" }}
              value={productData.CoffeeTaste}
              onChange={(e) =>
                setProductData({ ...productData, CoffeeTaste: e.target.value })
              }
              name="CoffeeTaste"
            />
            <TextField
              label="Price..."
              fullWidth
              sx={{ my: "10px" }}
              value={productData.Price}
              onChange={(e) =>
                setProductData({ ...productData, Price: e.target.value })
              }
              name="Price"
            />
            <TextField
              label="ShopifyLink..."
              fullWidth
              sx={{ my: "10px" }}
              value={productData.ShopifyLink}
              onChange={(e) =>
                setProductData({ ...productData, ShopifyLink: e.target.value })
              }
              name="ShopifyLink"
            />
            <TextField
              label="tag..."
              fullWidth
              sx={{ my: "10px" }}
              value={productData.tag}
              onChange={(e) =>
                setProductData({ ...productData, tag: e.target.value })
              }
              name="tag"
            />
            <Box>
              <label>Front</label>
              <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setProductData({ ...productData, ProductFront: base64 })
                }
              />
              <label>Back</label>

              <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setProductData({ ...productData, ProductBack: base64 })
                }
              />
            </Box>

            <Box textAlign={"center"}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </div>
  );
};

export default ProductForm;
