import { Box } from "@mui/material";
import React from "react";
import title from "../../../../assets/Home/6th/Slider/Slide_3/meticulous_roasting.png";
import curve from "../../../../assets/Home/6th/Slider/Slide_3/curve.png";
import beans_hour from "../../../../assets/Home/6th/Slider/Slide_3/beans_hour.png";
import trasn_text from "../../../../assets/Home/6th/Slider/Slide_3/transformation_text.png";
import text from "../../../../assets/Home/6th/Slider/Slide_3/slide_3_text.png";
import beansImg from "../../../../assets/Home/6th/Slider/Slide_3/been_image_illistration.png";
import exceptional from "../../../../assets/Home/6th/Slider/Slide_3/exceptional_coffee_drinking_experience.png";
import { useStyle } from "./style";

const Slide4Th = () => {
  const classes = useStyle();

  return (
    <>
      <Box my={"10%"}>
        <Box className={classes.title_box}>
          <Box component={"img"} src={title} alt="" className={classes.title} />
        </Box>
        <Box>
          <Box
            component={"img"}
            src={curve}
            alt=""
            className={classes.curveImage}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-around"}>
          <Box>
            <Box
              component={"img"}
              src={beans_hour}
              alt=""
              className={classes.beans_hour_Image}
            />
          </Box>
          <Box className={classes.trans_text_box}>
            <Box
              component={"img"}
              src={trasn_text}
              alt=""
              className={classes.trans_text_Image}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          sx={{ paddingTop: "5%" }}
        >
          <Box>
            <Box
              component={"img"}
              src={text}
              alt=""
              className={classes.textImg}
            />
          </Box>
          <Box className={classes.beansImg_box}>
            <Box
              component={"img"}
              src={beansImg}
              alt=""
              className={classes.beansImg}
            />
          </Box>
        </Box>
        <Box>
          <Box
            component={"img"}
            src={exceptional}
            alt=""
            className={classes.exceptional}
          />
        </Box>
      </Box>
    </>
  );
};

export default Slide4Th;
