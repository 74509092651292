import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Menu = () => {
  const [currentPage, setCurrentPage] = useState("");
  const pages = [
    { id: "1", title: "Home", to: "/" },
    //{ id: "2", title: "About Us", to: "/" },
    { id: "3", title: "Our Coffees", to: "/eleva-coffee-selection" },
    {
      id: "4",
      title: "Our Lounges",
      to: "https://elevacoffeelounge.com/",
    },
    { id: "5", title: "Our Blog", to: "/eleva-latest-news" },
    // { id: "6", title: "Post", to: "/eleva-latest-news/:id" },
    //{ id: "7", title: "Location", to: "/eleva-find-a-store" },
    { id: "11", title: "WholeSale", to: "/eleva-wholesale" },
    { id: "8", title: "Our Store", to: "/eleva-coffee-store" },
    //{ id: "10", title: "Dashboard", to: "/dashboard/mazen" },
  ];

  const location = useLocation();
  useEffect(() => {
    const currentPageObj = pages.find((page) => page.to === location.pathname);
    if (currentPageObj) {
      setCurrentPage(currentPageObj.title);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      sx={{
        paddingTop: "20px",
      }}
    >
      {pages.map((page) => {
        return (
          <Box
            paddingX={2}
            key={page.id}
            sx={{ width: { xl: "150px", lg: "135px" } }}
          >
            <NavLink to={page.to} style={{ textDecoration: "none" }}>
              <Typography
                color={"#fff"}
                borderBottom={currentPage === page.title ? "underline" : "none"}
                sx={{
                  borderBottom: currentPage === page.title ? "solid 3px" : "",
                  borderColor: "gold",
                  textDecorationColor: "gold",
                  fontSize: { xl: "20px", lg: "19px" },
                  "&:hover": {
                    color: "gold",
                  },
                }}
              >
                {page.title}
              </Typography>
            </NavLink>
          </Box>
        );
      })}
    </Box>
  );
};

export default Menu;
