import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  // Define your styles here

  /*** Outline_Second_Section ***/
  ///////////////////////////////
  second_section_outline: {
    width: "100%",
    "@media (min-width:0px)": {
      width: "100%",
      height: "150px",
      display: "flex",
    },
    "@media (min-width:600px)": {
      width: "100%",
      height: "250px",
      display: "flex",
    },
    "@media (min-width:900px)": {
      width: "100%",
      height: "350px",
      display: "flex",
    },
    "@media (min-width:1200px)": {
      width: "100%",
      height: "350px",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      width: "100%",
      height: "450px",
      display: "flex",
    },
  },

  sipImage: {
    "@media (min-width:0px)": { width: "100%", height: "10%" },
    "@media (min-width:600px)": { width: "60%", height: "10%" },
    "@media (min-width:900px)": { width: "60%", height: "10%" },
    "@media (min-width:1200px)": { width: "60%", height: "10%" },
    "@media (min-width:1536px)": { width: "60%", height: "10%" },
  },
  upperHand_up: {
    "@media (min-width:0px)": { width: "20%", marginTop: "20%" },
    "@media (min-width:600px)": { width: "20%", marginTop: "5%" },
    "@media (min-width:900px)": { width: "20%", marginTop: "5%" },
    "@media (min-width:1200px)": { width: "20%", marginTop: "5%" },
    "@media (min-width:1536px)": { width: "20%", marginTop: "5%" },
  },
  upperHand: {
    "@media (min-width:0px)": { width: "20%", height: "20%" },
    "@media (min-width:600px)": { width: "20%", height: "20%" },
    "@media (min-width:900px)": { width: "20%", height: "20%" },
    "@media (min-width:1200px)": { width: "20%", height: "20%" },
    "@media (min-width:1536px)": { width: "20%", height: "20%" },
  },
});
