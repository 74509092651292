import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  // Define your styles here
  Header: {
    position: "fixed",

    "@media (min-width:0px)": {
      display: "none",
    },
    "@media (min-width:600px)": {
      display: "none",
    },
    "@media (min-width:900px)": {
      display: "none",
    },
    "@media (min-width:1200px)": {
      position: "fixed",
      display: "flex",
    },
    "@media (min-width:1536px)": {
      display: "flex",
      position: "fixed",
    },
  },
  RHeader: {
    "@media (min-width:0px)": {
      display: "flex",
      marginBottom: "15%",
    },
    "@media (min-width:600px)": {
      display: "flex",
      marginBottom: "15%",
    },
    "@media (min-width:900px)": {
      display: "flex",
      marginBottom: "15%",
    },
    "@media (min-width:1200px)": {
      display: "none",
    },
    "@media (min-width:1536px)": {
      display: "none",
    },
  },
});
