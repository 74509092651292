import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import selection from "../../../../assets/selection.png";
import cup from "../../../../assets/empty_cup.png";
import { useStyles } from "./Style";

const Section1ST = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Box_Title}>
        <Box>
          <Box
            component={"img"}
            src={selection}
            className={classes.Box_Title_title}
          />

          <Box
            className={classes.Box_Title_subtitle}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Box>
              <h1 className={classes.Box_Title_subtitle_text}>Single Origin</h1>
            </Box>
            <Box>
              <NavLink to={"/eleva-coffee-store"}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#edd085",
                    borderRadius: "50px",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "goldenrod",
                    },
                  }}
                  className={classes.Btn_container}
                  size="large"
                >
                  <Box padding={1}>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "20px",
                          lg: "18px",
                          md: "16px",
                          sm: "16px",
                          xs: "16px",
                        },
                        textTransform: "capitalize",
                        color: "#000",
                        "&:hover": {
                          color: "#fff",
                        },
                      }}
                    >
                      Shop Now
                    </Typography>
                  </Box>
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box component={"img"} src={cup} className={classes.Box_Title_cup} />
        </Box>
      </Box>
    </>
  );
};

export default Section1ST;
