import React from "react";
import { Box, Typography } from "@mui/material";
import curve from "../../../assets/Home/curve.png";

const PostDetailSection = ({ postID, subtitle, content, sectionImg, left }) => {
  return (
    <>
      {left ? (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            flexWrap={"wrap"}
            py={2}
            key={postID}
          >
            <Box
              textAlign={"left"}
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              sx={{
                width: {
                  xl: "50%",
                  lg: "50%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            >
              <Box
                component={"img"}
                src={sectionImg}
                width={"100%"}
                zIndex={2}
              />
              <Box marginTop={"-30%"} marginLeft={"-15%"} sx={{}}>
                <Box component={"img"} src={curve} width={"50%"} />
              </Box>
            </Box>

            <Box
              sx={{
                textAlign: "left",
                width: {
                  xl: "40%",
                  lg: "50%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
                paddingLeft: {
                  xl: "5%",
                  lg: "10%",
                  md: "0%",
                  sm: "0%",
                  xs: "0%",
                },
              }}
            >
              <Typography variant="h3" py={2}>
                {subtitle}
              </Typography>
              <Typography variant="h4" py={2}>
                {content}
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            flexWrap={"wrap"}
            py={2}
            key={postID}
          >
            <Box
              sx={{
                textAlign: "left",
                width: {
                  xl: "50%",
                  lg: "50%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
                paddingRight: "10%",
                paddingLeft: "5%",
              }}
            >
              <Typography variant="h3" py={2}>
                {subtitle}
              </Typography>
              <Typography variant="h4" py={2}>
                {content}
              </Typography>
            </Box>
            <Box
              textAlign={"left"}
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              sx={{
                width: {
                  xl: "50%",
                  lg: "50%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            >
              <Box
                component={"img"}
                src={sectionImg}
                width={"100%"}
                zIndex={2}
              />
              <Box marginTop={"-30%"} marginLeft={"-15%"} sx={{}}>
                <Box component={"img"} src={curve} width={"50%"} />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default PostDetailSection;
