import { Box } from "@mui/material";
import React from "react";
import espresso from "../../../../assets/espresso.png";
import crema from "../../../../assets/Our_Coffee/2nd/crema.png";
import outline from "../../../../assets/outline.png";
import blends from "../../../../assets/Our_Coffee/2nd/blends.png";
import { useStyles } from "./Style";
import cup from "../../../../assets/empty_cup.png";
import crema_blend from "../../../../assets/Our_Coffee/2nd/crema_blend_text.png";
import brooklyn_blend from "../../../../assets/Our_Coffee/2nd/medium_blends_text.png";
import bean from "../../../../assets/Our_Coffee/2nd/bean.png";

const Section3RD = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        component={"img"}
        src={espresso}
        className={classes.espresso_title}
      />

      <Box className={classes.espresso_blends_box}>
        <Box component={"img"} src={crema} className={classes.cremaImg} />
        <Box component={"img"} src={blends} className={classes.blendsImg} />
      </Box>
      <Box component={"img"} src={cup} className={classes.cup} loading="lazy" />
      <Box component={"img"} src={outline} className={classes.outline} />

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ marginY: "7%" }}
        flexWrap={"wrap"}
      >
        <Box
          component={"img"}
          src={crema_blend}
          className={classes.crema_blend}
        />

        <Box component={"img"} src={bean} className={classes.bean} />
        <Box
          component={"img"}
          src={brooklyn_blend}
          className={classes.brooklyn_blend}
        />
      </Box>
    </>
  );
};

export default Section3RD;
