import { Box } from "@mui/material";
import React from "react";
import artistic from "../../../../assets/Home/6th/Slider/Slide_2/Artistic_Blending.png";
import curve from "../../../../assets/Home/6th/Slider/Slide_2/curve.png";
import text_hand from "../../../../assets/Home/6th/Slider/Slide_2/artistic_text_hands_illistration.png";
import nicaragua_mount from "../../../../assets/Home/6th/Slider/Slide_2/nicaragua_mountain_image.png";
import mountain from "../../../../assets/Home/6th/Slider/Slide_2/mountains_image.png";
import mountain_gold from "../../../../assets/Home/6th/Slider/Slide_2/mountain_gold.png";
import { useStyle } from "./style";
const Slide3Rd = () => {
  const classes = useStyle();
  return (
    <>
      <Box my={5}>
        <Box mt={0} display={"flex"} justifyContent={"space-around"}>
          <Box className={classes.artistic_box}>
            <Box
              component={"img"}
              src={artistic}
              className={classes.artistic_image}
            />
          </Box>
          <Box className={classes.curve_box}>
            <Box
              component={"img"}
              src={curve}
              className={classes.curve_image}
            />
          </Box>
        </Box>
        <Box mt={0} display={"flex"} justifyContent={"space-around"}>
          <Box className={classes.text_box}>
            <Box
              component={"img"}
              src={text_hand}
              className={classes.text_image}
            />
            <Box
              component={"img"}
              src={mountain}
              className={classes.mountain_image}
            />
          </Box>
          <Box
            component={"img"}
            src={nicaragua_mount}
            className={classes.nicaragua_mount_image}
          />
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            component={"img"}
            className={classes.mountain_gold_image}
            src={mountain_gold}
          />
        </Box>
      </Box>
    </>
  );
};

export default Slide3Rd;
