// useAuth.js

import { useState, useEffect } from "react";

// Custom hook to check if the user is authenticated
function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Implement your authentication logic here
    // For example, check if a token exists in local storage
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthenticated(true);
    }
  }, []);

  return authenticated;
}

export default useAuth;
