import React from "react";
import { WholeSaleDisc } from "./components";
import { Box } from "@mui/material";
import WholeSaleForm from "../../components/Shared/Form/WholeSaleForm/WholeSaleForm";

const wholesale = () => {
  return (
    <>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        sx={{
          marginTop: { xl: "10%", lg: "10%", md: "15%", sm: "25%", xs: "30%" },
        }}
      >
        <Box
          sx={{
            width: { xl: "50%", lg: "50%", md: "50%", sm: "100%", xs: "100%" },
          }}
        >
          <WholeSaleDisc />
        </Box>
        <Box
          sx={{
            width: { xl: "50%", lg: "50%", md: "50%", sm: "100%", xs: "100%" },
          }}
        >
          <WholeSaleForm />
        </Box>
      </Box>
    </>
  );
};

export default wholesale;
