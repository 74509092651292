import React from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Container,
  CssBaseline,
  Paper,
  Typography,
} from "@mui/material";

const WholeSaleForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper sx={{ padding: "20px", borderRadius: "8%" }} elevation={7}>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xl: "1.8rem",
                  lg: "1.5rem",
                  md: "1.5rem",
                  sm: "1.5rem",
                  xs: "1.5rem",
                },
                textAlign: "center",
              }}
            >
              Contact us for more information
            </Typography>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              {" "}
              <Grid item xs={6} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="First & LastName"
                  label="First & Last Name"
                  name="First & LastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="Business Name"
                  label="Business Name"
                  name="Business Name"
                  autoComplete="Business Name"
                />
              </Grid>
              <Grid item md={6} xs={6} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="phone Number"
                  label="Phone Number"
                  name="phone Number"
                  autoComplete="phone Number"
                />
              </Grid>
              <Grid item md={6} xs={6} sm={12}>
                <TextField
                  required
                  type="email"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="Address"
                  autoComplete="Address"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField
                  required
                  fullWidth
                  name="State"
                  label="State"
                  type="State"
                  id="State"
                  autoComplete="State"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField
                  required
                  fullWidth
                  name="City"
                  label="City"
                  type="City"
                  id="City"
                  autoComplete="City"
                />
              </Grid>
              <Grid item md={4} xs={4}>
                <TextField
                  required
                  fullWidth
                  name="ZIP Code"
                  label="ZIP Code"
                  id="ZIP Code"
                  autoComplete="ZIP Code"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  multiline
                  rows={3}
                  fullWidth
                  name="Message"
                  label="Message"
                  type="Message"
                  id="Message"
                  autoComplete="Message"
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ m: 3, borderRadius: "20px", height: "50px" }}
              >
                Submit
              </Button>
            </Grid>
          </Box>{" "}
        </Paper>
      </Container>
    </>
  );
};

export default WholeSaleForm;
