import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import MenuIcon from "@mui/icons-material/Menu";
//import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import LogoImg from "../../../assets/Navbar/logo_eleva_coffee.png";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
//import "./RHeaderStyle.css";
import useStyle from "./RHeaderStyle";
import logo from "../../../assets/logo-coffee.png";

const data = [
  {
    id: 1,
    title: "OurCoffee",
    link: "/eleva-coffee-selection",
  },
  {
    id: 2,
    title: "OurLounges",
    link: "https://elevacoffeelounge.com",
  },
  {
    id: 3,
    title: "OurBlog",
    link: "/eleva-latest-news",
  },
  {
    id: 4,
    title: "wholeSale",
    link: "/eleva-wholesale",
  },
  {
    id: 5,
    title: "Store",
    link: "/eleva-coffee-store",
  },
];
const ResHeader = () => {
  const classes = useStyle();

  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {data.map((item) => (
        <Box key={item.id}>
          <NavLink
            to={item.link}
            style={{
              textDecoration: "none",
              color: "#fff",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "goldenrod",
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon
                sx={{
                  ml: "10px",
                }}
              >
                <ArrowCircleRightIcon
                  fontSize="medium"
                  sx={{
                    color: "#fff",
                  }}
                />
              </ListItemIcon>
              <Typography sx={{ fontSize: "20px", py: "10px" }}>
                {item.title}
              </Typography>
            </ListItemButton>
          </NavLink>
          <Divider />
        </Box>
      ))}
    </Box>
  );
  return (
    <>
      <div>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "transparent",
            backdropFilter: "blur(4px)",
          }}
          className={classes.AppBar}
        >
          <Box
            sx={{
              display: "flex",
              paddingX: "3%",
              paddingY: "10px",
              justifyContent: "space-around",
            }}
          >
            <Grid xl={4} xs={4} item>
              <Box>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <MenuIcon sx={{ color: "#be974f", fontSize: "2.5rem" }} />
                    </IconButton>
                    <Drawer
                      sx={{ backdropFilter: "blur(4px)" }}
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      PaperProps={{
                        sx: {
                          backgroundColor: "#000",
                          backdropFilter: "blur(4px)",
                          opacity: "80%",
                          width: "40vh",
                        },
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "20px",
                            paddingBottom: "20px",
                            paddingTop: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              marginTop: "10px",
                              display: { md: "flex", sm: "flex", xs: "flex" },
                            }}
                          >
                            <Box>
                              <NavLink
                                to="/"
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  alt=""
                                  src={logo}
                                  width={"500px"}
                                  sx={{ width: { xs: "150px" } }}
                                />
                              </NavLink>
                            </Box>
                          </Box>
                        </Box>
                        {list(anchor)}
                      </Box>
                    </Drawer>
                  </React.Fragment>
                ))}
              </Box>
            </Grid>

            {/*-----------------Responsive Bar Icons--------------------- */}

            <Box sx={{ marginrRight: "-50%" }}>
              <NavLink className={classes.Nav} to="/">
                <Box
                  component="img"
                  src={LogoImg}
                  className={classes.logo}
                  alt="eleva_coffee_logo"
                />
              </NavLink>
            </Box>

            <Box sx={{ paddingTop: "10px" }}>
              <NavLink className={classes.Nav} to="/eleva-coffee-store">
                <Box display={"flex"} justifyContent={"space-evenly"}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      paddingTop: "12%",
                    }}
                  >
                    <LocalGroceryStoreIcon
                      fontSize="large"
                      sx={{ color: "#be974f" }}
                    />
                    <Typography fontSize="20px" sx={{ color: "#be974d" }}>
                      Shop
                    </Typography>
                  </Box>
                </Box>
              </NavLink>
            </Box>
          </Box>
        </AppBar>
      </div>
    </>
  );
};

export default ResHeader;
