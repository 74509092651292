import React from "react";
import logo from "../../../../assets/logo_mini.png";
import slogen from "../../../../assets/slogen.png";
import bg from "../../../../assets/Home/1st/coffee_bg.jpg";
//import "./style.css";
import { useStyles } from "./style";

import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const Section1Th = () => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{}}>
        <Box className={classes.hero_bg}>
          <Box
            component={"img"}
            alt={"alt"}
            src={bg}
            className={classes.hero_bg}
          />
        </Box>
        <Box className={classes.hero_container}>
          <Box
            component={"img"}
            src={logo}
            alt={"alt"}
            className={classes.hero_container_logo}
          />

          <Box
            component={"img"}
            src={slogen}
            alt={"alt_slogen"}
            className={classes.slogen}
          />

          <Box className={classes.Container_btn}>
            <NavLink to={"/eleva-coffee-selection"}>
              <Button
                variant={"outlined"}
                className={classes.hero_btn}
                sx={{
                  backgroundColor: "#edd085",
                  borderRadius: "50px",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "goldenrod",
                  },
                }}
              >
                <Box padding={1}>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "20px",
                        lg: "18px",
                      },
                      textTransform: "capitalize",
                      color: "#000",
                      "&:hover": {
                        color: "#fff",
                      },
                    }}
                  >
                    Explore Our Coffee
                  </Typography>
                </Box>
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Section1Th;
