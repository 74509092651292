import { Outlet } from "react-router-dom";
import React from "react";

const Layout = () => {
  return (
    <>
      <div style={{ padding: "20px" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
