import React, { useState, useEffect } from "react";
import { Paper, TextField, Box, Button } from "@mui/material";
import FileBase from "react-file-base64";
import { createPost, updatePost } from "../../../../actions/posts";
import { useSelector, useDispatch } from "react-redux";

const PostForm = ({ currentId, setCurrentId }) => {
  const [postData, setPostData] = useState({
    title: "",
    content: "",
    selectedFile: "",
    selectedFile_1st: "",
    subtitle_1st: "",
    content_1st: "",
    selectedFile_2nd: "",
    subtitle_2nd: "",
    content_2nd: "",
    selectedFile_3rd: "",
    subtitle_3rd: "",
    content_3rd: "",
    author: "",
    category: "",
    tags: "",
  });
  const post = useSelector((state) =>
    currentId ? state.posts.find((message) => message._id === currentId) : null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);

  const clear = () => {
    setCurrentId(0);
    setPostData({
      title: "",
      content: "",
      selectedFile: "",
      selectedFile_1st: "",
      subtitle_1st: "",
      content_1st: "",
      selectedFile_2nd: "",
      subtitle_2nd: "",
      content_2nd: "",
      selectedFile_3rd: "",
      subtitle_3rd: "",
      content_3rd: "",
      author: "",
      category: "",
      tags: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createPost(postData));
      clear();
    } else {
      dispatch(updatePost(currentId, postData));
      clear();
    }
  };

  return (
    <div>
      <Box display={"flex"} justifyContent={"flex-start"} gap={5}>
        <Paper sx={{ padding: "20px", borderRadius: "15px" }}>
          <h2>Create New Post</h2>

          <form
            onSubmit={handleSubmit}
            method="POST"
            enctype="multipart/form-data"
          >
            <Box>
              <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setPostData({ ...postData, selectedFile: base64 })
                }
              />
            </Box>
            <TextField
              label="Post title ..."
              fullWidth
              sx={{ my: "10px" }}
              value={postData.title}
              onChange={(e) =>
                setPostData({ ...postData, title: e.target.value })
              }
              name="title"
            />
            <TextField
              label="Content ..."
              fullWidth
              multiline
              rows={3}
              sx={{ my: "10px" }}
              value={postData.content}
              onChange={(e) =>
                setPostData({ ...postData, content: e.target.value })
              }
              name="content"
            />

            <Box>
              <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setPostData({ ...postData, selectedFile_1st: base64 })
                }
              />
            </Box>

            <TextField
              label="subtitle_1st ..."
              fullWidth
              sx={{ my: "10px" }}
              value={postData.subtitle_1st}
              onChange={(e) =>
                setPostData({ ...postData, subtitle_1st: e.target.value })
              }
              name="subtitle_1st"
            />
            <TextField
              label="content_1st ..."
              fullWidth
              sx={{ my: "10px" }}
              value={postData.content_1st}
              onChange={(e) =>
                setPostData({ ...postData, content_1st: e.target.value })
              }
              name="content_1st"
            />

            <Box>
              <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setPostData({ ...postData, selectedFile_2nd: base64 })
                }
              />
            </Box>

            <TextField
              label="subtitle_2nd..."
              fullWidth
              sx={{ my: "10px" }}
              value={postData.subtitle_2nd}
              onChange={(e) =>
                setPostData({ ...postData, subtitle_2nd: e.target.value })
              }
              name="subtitle_2nd"
            />
            <TextField
              label="content_2nd..."
              fullWidth
              sx={{ my: "10px" }}
              value={postData.content_2nd}
              onChange={(e) =>
                setPostData({ ...postData, content_2nd: e.target.value })
              }
              name="content_2nd"
            />
            <Box>
              <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setPostData({ ...postData, selectedFile_3rd: base64 })
                }
              />
            </Box>

            <TextField
              label="subtitle_3rd..."
              fullWidth
              sx={{ my: "10px" }}
              value={postData.subtitle_3rd}
              onChange={(e) =>
                setPostData({ ...postData, subtitle_3rd: e.target.value })
              }
              name="subtitle_3rd"
            />
            <TextField
              label="content_3rd..."
              fullWidth
              sx={{ my: "10px" }}
              value={postData.content_3rd}
              onChange={(e) =>
                setPostData({ ...postData, content_3rd: e.target.value })
              }
              name="content_3rd"
            />

            <Box textAlign={"center"}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </div>
  );
};

export default PostForm;
